import { Field } from "formik";
import React from "react";
import BasicField from "./BasicField";

export default function RadioButtonField(props) {
    return (
        <div className="mt-10">
            <BasicField fname={`${props.name}_radio`}>

                <label htmlFor={`${props.name}_radio`} className="inline-block text-gray-700">{props.title}</label>
                <div className="mt-3">
                    <span className="">
                        <Field type="radio" name={`${props.name}_radio`} value="yes" />
                        <span className="ml-2">Yes</span>
                    </span>

                    <span className="ml-8">
                        <Field type="radio" name={`${props.name}_radio`} value="no" />
                        <span className="ml-2">No</span>
                    </span>
                </div>
            </BasicField>
            <BasicField fname={props.name}>

                <label htmlFor={props.name} className="inline-block text-gray-700 mt-4">Evidence / comment</label>
                <Field
                    type="text"
                    id={props.name}
                    name={props.name}
                    placeholder=""
                    className="flex flex-col w-full px-4 py-1.5 text-base text-gray-900 bg-white bg-clip-padding border border-solid border-gray-400 transition ease-in-out m-0 hover:border-gray-600 focus:text-gray-700 focus:bg-white focus:outline-none"
                />
            </BasicField>
        </div>
    );
}