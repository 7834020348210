const sharepoint_logic_app_endpoint = "https://prod-29.germanywestcentral.logic.azure.com:443/workflows/64d5775e046640c2b852d7204b3cd5ac/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=eLiWH1pEP_dyyjUq1JPzmKcDYk-1uJhGeQyei5KKjOM";
const blob_directory_logic_app_endpoint = "https://prod-19.germanywestcentral.logic.azure.com:443/workflows/ddd50bc2fd2a4baabd850aaa48ec55d2/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=iBK7PuZWKzQUPpn0UghcFL5HEXQ4KC-V4sHHhvsAZAw";

const static_sas_key = "?sv=2021-06-08&ss=b&srt=co&sp=rwlactfx&se=2025-01-01T00:32:03Z&st=2022-07-28T15:32:03Z&spr=https&sig=BXliQIWLN5KyQSwFeHVkbjnCfQ1Ul5j%2BGxpHNhoomHc%3D";

const blob_url = "https://qatpddformularuploaddata.blob.core.windows.net";
const container = 'formular-files';

const Settings = {
    'sharepoint_endpoint': sharepoint_logic_app_endpoint,
    'blob_endpoint': blob_directory_logic_app_endpoint,
    'sas_key': static_sas_key,
    'blob_url': blob_url,
    'container': container
}

const InitialValues = {    
    vCompanyName: "",
    vIsQualityManagement: "",
    vIsQualityManualAvailable: "",
    vAreRegularManagementReviewQuality: "",
    vHasSubsidiaryInspected: "",
    vDidCriticalFindingsInspections: "",
    vWhichClientAuditTypes: "",
    vGlobalSOPs: "",
    vIsSystemForNonConformances: "",
    vIsSystemForChangeControl: "",
    vCustomerComplaintHandling: "",
    vHasBusinessContinuityPlan: "",
    vContingencyPlan: "",
    vDisasterRecoveryPlan: "",
    vIsRedundantInternetConnection: "",
    vIsGlobalSystemDB: "",
    vHasDataBackups: "",
    vArePhysicalDataBackups: "",
    vHasChangeControlSystem: "",
    vIsSystemValidated: "",
    vHasDataIntegrity: "",
    vAreSubcontractorsUsed: "",
    vHasSubcontractorSelectionStandards: "",
    vYearOfFoundation: "",
    vTypeOfCompany: "",
    vCEOName: "",
    vDataProtectionOfficerName: "",
    vLargestOwners: "",
    vParentCompany: "",
    vPlaceOfJurisdiction: "",
    vMainBusinessOrService: "",
    vNumberOfEmployees: "",
    vAquisitions: "",
    vQualitySystemStandards: "",
    vClientAuditNumber: "",
    vQADepartmentOrganization: "",
    vStaffingLevel: "",
    vInternalAuditSystem: "",
    vDescriptionSOPSystem: "",
    vDescriptionStaffTrainingSystem: "",
    vArchivingSystem: "",
    vDescriptionITInfrastructure: "",
    vDescriptionITApplications: "",
    vMaximumDataLossServer: "",
    vMaximumDataLossOnPrem: "",
    vSubcontractorQualificationTracking: "",
    vSubcontractorQualityAgreements: "",
    vSubcontractorCollaborationDescription: "",
    vSubcontractorIssueManagement: "",
    vSubcontractorDataProcessingAgreements: "",
    vInfluenceCompanyDecision: "",
    vCloseRelationship: "",
    vSubjectCriminalConviction: "",
    vTradeSanctionList: "",
    vComplianceProgramEthics: "",
    vEmployeeComplianceTraining: "",
    vCommitToCoC: "",
    vSustainabilityESG: "",
    vCertifications: "",
    vMainContactPersons: "",
    vAnnualChangeInBusinessVolume: "",
    vComputerizedSystems: "",
    
    vIsQualityManagement_radio: "",
    vIsQualityManualAvailable_radio: "",
    vAreRegularManagementReviewQuality_radio: "",
    vHasSubsidiaryInspected_radio: "",
    vDidCriticalFindingsInspections_radio: "",
    vWhichClientAuditTypes_radio: "",
    vGlobalSOPs_radio: "",
    vIsSystemForNonConformances_radio: "",
    vIsSystemForChangeControl_radio: "",
    vCustomerComplaintHandling_radio: "",
    vHasBusinessContinuityPlan_radio: "",
    vContingencyPlan_radio: "",
    vDisasterRecoveryPlan_radio: "",
    vIsRedundantInternetConnection_radio: "",
    vIsGlobalSystemDB_radio: "",
    vHasDataBackups_radio: "",
    vArePhysicalDataBackups_radio: "",
    vHasChangeControlSystem_radio: "",
    vIsSystemValidated_radio: "",
    vHasDataIntegrity_radio: "",
    vAreSubcontractorsUsed_radio: "",
    vHasSubcontractorSelectionStandards_radio: "",

    acceptForm: false,
    acceptProcessing:false,

    vSigner: '',
}

export { Settings, InitialValues }