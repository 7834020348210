import { Route, Routes } from 'react-router-dom';

import Success from '../Success';
import HomeQATPDD from '../QATPDD/HomeQATPDD';



export default function Routings() {
  return (

        <Routes>
          <Route path="/" element={<HomeQATPDD />} />
          <Route path="/success" element={<Success title={"QA TPDD QUESTIONNAIRE"} />} />
        </Routes>
  );
}
