import { Form } from 'formik';
import BasicForm from '../components/BasicForm';
import {
    InputField,
    Section,
    SubmitButton,
    FileUploadField,
    TableField,
    RadioButtonField,
    CheckBox,
    InputTextArea
} from '../components/Components';
import { useNavigate } from 'react-router-dom';
import YearTableField from '../components/YearTableField';
import { Settings, InitialValues } from './Settings';
import * as Yup from 'yup';
import UtilFactory from '../Utilities';
import React from 'react';
import { useState } from 'react';
let Util = UtilFactory(Settings, InitialValues);


let validationSchema = {
    ...Util.defaultValidationScheme,
    vCompanyName: Yup.string().required('Required').max(40, 'must be less than 40 characters').matches(/^[\w\d\s.,üäö]*$/,"Input may only contain the following characters: A-Za-z0-9,.äöü"),
    acceptForm: Yup.bool().oneOf([true], 'Accept'),
    acceptProcessing: Yup.bool().oneOf([true], 'Accept'),
    vSigner: Yup.string().email().required('Required'),
}

export default function HomeQuestionaire() {
    //TODO does this work? also don't forget the other home javascript file
    let [showloader, setb] = useState(false);
    const submitHandler = Util.submitHandler(useNavigate(), (val) => setb(val));
    return (
        <div style={{ backgroundImage: "url(/affimed_background.jpg)" }}
            className="bg-fixed bg-cover bg-no-repeat bg-center">
            <div className="flex flex-col flex-wrap items-center pt-10">
                <BasicForm
                    initialValues={InitialValues}
                    // TODO this should probably be in Settings, so can also pass it to the success page
                    title={"QA TPDD QUESTIONNAIRE"}
                    submitHandler={submitHandler}
                    validationSchema={Yup.object().shape(validationSchema)}>
                    <Form>
                        <Section title="I. General Information">
                            <InputField
                                name="vCompanyName"
                                title="1. Company Name" />
                            <InputTextArea
                                name="vYearOfFoundation"
                                title="2. Year of foundation" />
                            <InputTextArea
                                name="vTypeOfCompany"
                                title="3. Type of company (privately owned, public)" />
                            <InputTextArea
                                name="vCEOName"
                                title="4. Chief Executive Officer" />
                            <InputTextArea
                                name="vDataProtectionOfficerName"
                                title="5. Data Protection Officer (per art. 37 GDPR)" />
                            <InputTextArea
                                name="vLargestOwners"
                                title="6. Largest owners (if applicable)" />
                            <InputTextArea
                                name="vParentCompany"
                                title="7. Parent company (if applicable)" />
                            <InputTextArea
                                name="vPlaceOfJurisdiction"
                                title="8. Place of jurisdiction" />
                            <InputTextArea
                                name="vMainBusinessOrService"
                                title="9. Main business / services" />
                            <InputTextArea
                                name="vNumberOfEmployees"
                                title="10. Total number of employees" />
                            <InputTextArea
                                name="vAquisitions"
                                title="11. Acquisitions / mergers or a change in core business recently occurred, in progress or planned?" />

                            <TableField
                                name="vMainContactPersons"
                                title="12. Main contact persons (including QA):"
                                allowMultipleRows
                                columns={['Name', 'Title', 'Department', 'Phone No. /email address']} />
                            <YearTableField
                                name="vAnnualChangeInBusinessVolume"
                                title="13. Annual change in business volume (in %) (for the last 5 years)" />
                            <FileUploadField
                                name="vOrganizationChartAttachment"
                                title="14. Organization chart" />
                            <FileUploadField
                                name="vListOfControlledDocumentsAttachments"
                                title="15. List of controlled documents (SOPs)" />
                            <FileUploadField
                                name="vListOfServicesAttachments"
                                title="16. List of services" />
                            <FileUploadField
                                name="vCopiesOfCertificatesAttachments"
                                title="17. Copies of certificates and accreditations" />
                            <FileUploadField
                                name="vCompanyPresentationAttachments"
                                title="18. Company presentation / brochures / web site" />
                        </Section>

                        <Section title="II. Quality Management">
                            <RadioButtonField
                                name="vIsQualityManagement"
                                title="19. Is a document Quality Management System established?" />
                            <RadioButtonField
                                name="vIsQualityManualAvailable"
                                title="20. Is a Quality Manual available" />

                            <RadioButtonField
                                name="vAreRegularManagementReviewQuality"
                                title="21. Are regular management reviews of quality issues performed?" />
                            <InputTextArea
                                name="vQualitySystemStandards"
                                title="22. Please list the standards your quality system complies with (e.g. GxP, ISO 9001, ISO 15189, others):" />
                            <RadioButtonField
                                name="vHasSubsidiaryInspected"
                                title="23. Has a subsidiary of your company in the relevant regions been inspected in the past 5 years? If yes, by which authority and when?" />
                            <RadioButtonField
                                name="vDidCriticalFindingsInspections"
                                title="24. Did any major or critical findings occur during the inspections? If yes, please give the number
                                of major and critical findings and the area in which they occurred (e.g. GxP compliance, regulatory compliance, quality system, IT systems, ...)" />
                            <RadioButtonField
                                name="vWhichClientAuditTypes"
                                title="25. Which type of client audits are usually conducted (qualification, re-qualification, for cause)?
                                Are clients charged for availability of personnel during audits?" />
                            <InputTextArea
                                name="vClientAuditNumber"
                                title="26. Please give the number of client audits which have been performed in your company in the 
                                last 12 months and the regions in which they occurred:" />
                            <InputTextArea
                                name="vQADepartmentOrganization"
                                title="27. How is the QA department organized?" />
                            <InputTextArea
                                name="vStaffingLevel"
                                title="28. Please give a brief overview of the staffing level QA (headcount, FTE) and experience
                                (number of QA personnel with >3, >5, and >10 years of experience)" />
                            <InputTextArea
                                name="vInternalAuditSystem"
                                title="29. Please describe your internal audit system including CAPA follow-up." />
                            <RadioButtonField
                                name="vGlobalSOPs"
                                title="30. Are global SOPs used for all quality and service processes, i.e. same standards for all subsidiaries?" />
                            <InputTextArea
                                name="vDescriptionSOPSystem"
                                title="31. Please give a brief description of the SOP system (SOP development, responsibilities, maintenance, review cycles)" />
                            <InputTextArea
                                name="vDescriptionStaffTrainingSystem"
                                title="32. Please give a brief description of your staff training system" />
                            <RadioButtonField
                                name="vIsSystemForNonConformances"
                                title="33. Do you have a system to handle non-conformances? Please describe" />
                            <RadioButtonField
                                name="vIsSystemForChangeControl"
                                title="34. Do you have a system for change control? Please describe" />
                            <InputTextArea
                                name="vArchivingSystem"
                                title="35. Please describe your system for archiving" />
                            <RadioButtonField
                                name="vCustomerComplaintHandling"
                                title="36. Do you have a system to for handling of customer complaints?" />
                            <RadioButtonField
                                name="vHasBusinessContinuityPlan"
                                title="37. Do you have a Business Continuity Plan?" />
                        </Section>

                        <Section title="III. IT Systems">
                            <InputTextArea
                                name="vDescriptionITInfrastructure"
                                title="38. Please give a brief description of your IT infrastructure. E.g., own infrastructure or 
                                rented server farm? Physical security measures, environmental control, fire protection? 
                                High availability implemented?" />
                            <RadioButtonField
                                name="vContingencyPlan"
                                title="39. Has a contingency plan been established in which IT systems are covered? 
                                Is there any alternative data repository?" />
                            <RadioButtonField
                                name="vDisasterRecoveryPlan"
                                title="40. Is a disaster recovery plan established?" />
                            <RadioButtonField
                                name="vIsRedundantInternetConnection"
                                title="41. Is a redundant internet connection or service level agreement regarding the availability 
                                of the company’s internet connection in place?" />
                            <RadioButtonField
                                name="vIsGlobalSystemDB"
                                title="42. Is a global system/database used (i.e., subsidiaries do not use separate systems)?" />
                            <InputTextArea
                                name="vDescriptionITApplications"
                                title="43. Please give a brief description of your IT applications/modules." />
                            <RadioButtonField
                                name="vHasDataBackups"
                                title="44. Are data backups performed on media of a different type than the original storage?" />
                            <RadioButtonField
                                name="vArePhysicalDataBackups"
                                title="45. Are data backups stored physically separated from the original data?" />
                            <InputTextArea
                                name="vMaximumDataLossServer"
                                title="46. What is the maximum data loss in case of destruction of the servers?" />
                            <InputTextArea
                                name="vMaximumDataLossOnPrem"
                                title="47. What is the maximum data loss in case of destruction of the company’s premises?" />
                            <RadioButtonField
                                name="vHasChangeControlSystem"
                                title="48. Is there a change control process for all changes within the IT system?" />
                            <RadioButtonField
                                name="vIsSystemValidated"
                                title="49. Are all IT systems used for processing of data validated?" />
                            <RadioButtonField
                                name="vHasDataIntegrity"
                                title="50. Do you have a system to ensure data integrity?" />
                        </Section>

                        <Section title="IV. Subcontractors">
                            <RadioButtonField
                                name="vAreSubcontractorsUsed"
                                title="51. Is a vendor/subcontractor used for any of the requested services in any region?
                                If yes, please enter the name of the subcontractor and contact details." />
                            <RadioButtonField
                                name="vHasSubcontractorSelectionStandards"
                                title="52. Are standards established for subcontractor selection, assessment, and performance tracking?" />
                            <InputTextArea
                                name="vSubcontractorQualificationTracking"
                                title="53. Please give a brief description of your approach to subcontractor selection, 
                                (re-) qualification and performance tracking." />
                            <InputTextArea
                                name="vSubcontractorQualityAgreements"
                                title="54. Are Quality Agreements in place with subcontractors?" />
                            <InputTextArea
                                name="vSubcontractorCollaborationDescription"
                                title="55. Please give a brief description of the collaboration with a subcontractor during a project,
                                e.g. communication, interfaces, data transfer.." />
                            <InputTextArea
                                name="vSubcontractorIssueManagement"
                                title="56. Please give a brief description of issue management and involvement/communication lines 
                                to sponsor regarding vendors/subcontractors." />
                            <InputTextArea
                                name="vSubcontractorDataProcessingAgreements"
                                title="57. Are controller-processor agreements (per GDPR) in place with all vendors involved in data processing?
                                Do you have your own template for a data-processor agreement?" />
                        </Section>

                        <Section
                            title="V. Computerized Systems"
                            subtitle="-	Please describe briefly how the following topics are managed for each 
                            relevant computerized system used in the proposed services (table may be copied)">

                            <TableField
                                name="vComputerizedSystems"
                                title="58. Computerized Systems"
                                allowMultipleRows
                                columns={['System name', 'Validation', 'Rights & Roles Management', 'System Usage and Data Oversight',
                                    'Critical Data for the System', 'Audit Trail set-up and Management',
                                    'System Maintenance', 'Back-Up & Archiving', 'Electronic Signature']} />
                        </Section>

                        <Section title="VI. Integrity">
                            <InputTextArea
                                name="vInfluenceCompanyDecision"
                                title="59. To the best knowledge of the Company, has any of the Shareholders or any Company Board Member, Executive or employee who would be in a position to influence a Company business decision, which is relevant for the business relationship with Affimed, or one of their Family Members ever served for or been employed by a government (also government agency etc.; 'politically exposed person')? " />
                            <InputTextArea
                                name="vCloseRelationship"
                                title="60. To the best knowledge of the Company, has any of the Shareholders or any Company Board Member, Executive or employees or any of their Family Members a close personal or business relationship with any Affimed Board Member, Executive or employee who would be in a position to influence Affimed business decisions that provide a commercial advantage the individual or the Affimed Board Member, Executive or employee ('conflict of interest')? If so, please provide details. " />
                            <InputTextArea
                                name="vSubjectCriminalConviction"
                                title="61. Within the past 5 years, has the Company or, to the best knowledge of the Company, any of the Shareholders or any Company Executive or employee who is relevant for the business relationship with Affimed been subject to any criminal conviction, investigation, data protection (GDPR) inspection, or any similar legal proceeding? If so, please provide details." />
                            <InputTextArea
                                name="vTradeSanctionList"
                                title="62. Within the past 5 years, has the Company or, to the best knowledge of the Company, any of the Shareholders or any Company Executive or employee been put on a trade sanction list (e.g., an OFAC list)? If so, please provide details. " />
                        </Section>

                        <Section title="VII. Compliance Program">
                            <InputTextArea
                                name="vComplianceProgramEthics"
                                title="63. Does the Company have a compliance /data protection program, in particular a code of ethics or a similar policy? If so, please provide the respective information (including attachments)." />
                            <FileUploadField
                                name="vComplianceProgramEthicsAttachments"
                                title="64. File Attachments to previous question" />
                            <InputTextArea
                                name="vEmployeeComplianceTraining"
                                title="65. How often do you train your employees regarding your compliance / data protection program? Please provide details." />
                            <InputTextArea
                                name="vCommitToCoC"
                                title={
                                    <>
                                        66. Does the Company commit to the values and standards set out in Affimed's Code of Conduct for Business Partners (see <a className="text-red-500" target="_blank" rel="noreferrer" href='https://www.affimed.com/wp-content/uploads/Affimed-2022-CodeOConduct-Partners-FINAL.pdf'>Affimed Code of Conduct</a>)? If not, please explain to what standards the Company commits itself (including attachments).
                                    </>
                                } />
                            <FileUploadField
                                name="vCommitToCoCAttachments"
                                title="67. File Attachments to previous question" />
                        </Section>

                        <Section title="VIII. Other">
                            <InputTextArea
                                name="vSustainabilityESG"
                                title="68. Please provide details on any Sustainability or ESG program of the Company and, where available, certifications such as […] (including attachments)." />
                            <FileUploadField
                                name="vSustainabilityESGAttachments"
                                title="69. File Attachments to previous question" />
                            <InputTextArea
                                name="vCertifications"
                                title="70. Where available, please provide details on any certifications of the Company's IT systems (including attachments). " />
                            <FileUploadField
                                name="vCertificationsAttachments"
                                title="71. File Attachments to previous question" />
                            <CheckBox
                                name={"acceptForm"}
                                title={
                                    <>
                                        72. The Company confirms that, to its best knowledge, all responses are complete and accurate. In particular, Company confirms that it has a legal basis for providing Affimed with these data which, if required (e.g. because the processing requires a data subject's consent), includes the processing by Affimed as described in the Data Privacy Policy.
                                    </>
                                }                                
                                type={"checkbox"}
                                label="I Agree" />
                        </Section>

                        <Section
                            title="IX. Signing">

                            <InputField
                                name="vSigner"
                                title="73. Please enter your email address in order to sign this document via Adobe Sign." />

                        </Section>

                        <SubmitButton showLoader={showloader} site={"QA-TPDD"} />
                    </Form>
                </BasicForm>
            </div>
        </div >
    );
}
