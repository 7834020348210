import { useFormikContext } from "formik";
import React from "react";
import { useState } from "react";
import BasicField from "./BasicField";

import TableRows from "./TableRow";


export default function YearTableField(props) {
    const latest_year = new Date().getFullYear() - 1;

    const fixedColumnNames = [latest_year - 4, latest_year - 3, latest_year - 2, latest_year - 1, latest_year];

    const state_dict = {}
    for (let i = 0; i < fixedColumnNames.length; i++) {
        state_dict[fixedColumnNames[i]] = ""
    }

    const formikProps = useFormikContext();
    const [rowsData, setRowsData] = useState([{ ...state_dict }]);

    const deleteTableRows = (index) => {
        const rows = [...rowsData];

        rows.splice(index, 1);
        setRowsData(rows);

        recreateFullStringFromTableFields();
    }

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const rowsInput = [...rowsData];

        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        //SetTimeout allows the keystroke to be drawn when holding down a key.
        setTimeout(recreateFullStringFromTableFields);
    }

    const recreateFullStringFromTableFields = () => {
        let full_string = "";

        full_string = Object.entries(rowsData[0]).map(([name, value]) => value ? `${name}: ${value}%` : `${name}: `).join(', ')

        //console.log(full_string);
        formikProps.setFieldValue(props.name, full_string);
    }

    return (
        <div className="mt-10">
            <BasicField fname={props.name}>

                <label htmlFor={props.name} className="inline-block text-gray-700">{props.title}</label>
                <table className="mt-4 bg-gray-400;">
                    <thead>
                        <tr>
                            {fixedColumnNames.map((column) => (
                                <th key={column} className="">{column}</th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                        <TableRows
                            rowsData={rowsData}
                            deleteTableRows={deleteTableRows}
                            handleChange={handleChange}
                            allowMultipleRows={props.allowMultipleRows}
                            columnNames={fixedColumnNames} />
                    </tbody>
                </table>
            </BasicField>

        </div>
    );
}